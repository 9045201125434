<template>
  <div>
    <div class="content">
      <div class="all">
        <div class="SearchBar">
          <!--搜索框-->
          <el-form ref="search" :inline="true" :model="search" size="small" style="width:1600px">
            <el-form-item label="第三方施工队" class="lab">
              <el-input
                v-model="search.teamName"
                placeholder="请输入第三方施工队"
                class="SearchInput"
                clearable
              ></el-input>
            </el-form-item>
           
            <el-form-item label="申请类型" class="lab">
              <el-select v-model="search.typeCode" clearable placeholder="请选择">
                <el-option
                v-for="item in applyList"
                :key="item.value"
                :label="item.label"
                
                :value="item.value">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="状态" class="lab">
              <el-select v-model="search.status" clearable placeholder="请选择">
                <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                
                :value="item.value">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item  class="lab" label="申请时间">
               <el-date-picker
                v-model="searchDatatime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
                value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <span >
            <el-button type="primary" class="SearchBtn" size="small" @click="getList(1)">查询</el-button>
            <el-button type="primary" plain class="SearchBtn2" size="small" @click="reset">重置</el-button>
            </span>
          </el-form>
        </div>
        <div class="table">
          
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin: 15px 0;"
            border
              height="calc(100vh - 345px)"
            :stripe="true"
            @sort-change="sort_change" 
            :default-sort = "{prop: 'createTime', order: 'descending'}">>
           <el-table-column
                type="index"
                label="序号"
                width="70" align="center">
                </el-table-column>
            <el-table-column
              prop="contractCode"
              label="合同编号"
              align="center"
              show-overflow-tooltip
            >
            
            </el-table-column>
            <el-table-column
              prop="teamName"
              label="第三方施工队"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="contractTypeName"
              label="合同类型"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="startDate"
              label="签订日期"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="endDate"
              label="截至日期"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="annexUrl"
              label="合作附件"
              align="center"
              show-overflow-tooltip
            ><template slot-scope="{ row }">
              <a :href="row.annexUrl" download="" target="_back">查看</a>
              </template>
            </el-table-column>
            <el-table-column
              prop="typeName"
              label="申请类型"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="createBy"
              label="申请人"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="申请时间"
              align="center"
              show-overflow-tooltip
              sortable="custom"></el-table-column>
               <el-table-column
              prop="reason"
              label="申请原因"
              align="center"
              show-overflow-tooltip width="150px"></el-table-column>
            <el-table-column
              prop="statusName"
              label="状态"
              align="center"
              show-overflow-tooltip
            >
            <template slot-scope="{ row }">
              <el-tag v-if="row.statusCode==2" type="info" size="small" plain>{{row.statusName}}</el-tag>
              <el-tag v-if="row.statusCode==1" type="primary" size="small" plain>{{row.statusName}}</el-tag>
            </template>
            </el-table-column>
            <el-table-column prop="operation" label="操作" align="left">
              <template slot-scope="{ row }">
                    <el-button v-if="row.statusCode==2"
                    type="primary"
                    size="small" @click="desc(row)"
                    >处理详情</el-button>
                   <el-button v-if="row.statusCode==1"
                    type="success"
                    size="small"
                    @click="apply(row)"
                    >同意</el-button>
                    
                    
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
          <el-pagination
            :current-page="search.current"
            :background="true"
            :page-sizes="[ 30, 50, 100]"
            :page-size="search.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        </div>
      </div>
    </div>
   
    <el-dialog
      :title="applyName+'审核'"
      :visible.sync="dialogVisibleApply"
      width="30%"
      :close-on-click-modal="false"
      @close="clearnF('form')" >
      <el-form ref="Applyform" :model="form" label-width="120px" size="mini" :rules="rulesApp" >
        <div>
          <el-form-item :label="applyName+'原因：'" prop="positionName">
           <el-input type="textarea" v-model="form.reason" :autosize="{ minRows: 2, maxRows: 4}" disabled></el-input>
          </el-form-item>
          <el-form-item label="终止日期：" prop="positionCode" v-if="applyflag=='stop'">
            <el-date-picker  v-model="form.stopDate" type="date" :picker-options="pickerOptions" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
          </el-form-item>
          
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button style="margin-right:10px" @click="dialogVisibleDesc = false">取消</el-button> -->
        <el-button type="primary" @click="saveApply">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="处理详情"
      :visible.sync="dialogVisibleDesc"
      width="30%"
      :close-on-click-modal="false"
      @close="clearnF('form')" >
      <el-form :inline="true" ref="addform" :model="form" label-width="120px" size="mini" >
        <div>
          <el-row>
             <el-col :span="24">
                <el-form-item label="处理结果：" prop="positionName">
                {{form.tableType=='stop'?'同意终止':'同意作废'}}
                </el-form-item>
            </el-col>
          </el-row>
          <el-row>
             <el-col :span="24">
                <el-form-item label="操作人:" prop="positionCode">
                {{form.createBy}}
                </el-form-item>
             </el-col>
          </el-row>
          <el-row>
             <el-col :span="24">
          <el-form-item label="操作时间:" >
            <span style="word-break:break-all">{{form.createTime}}</span>
          </el-form-item>
          </el-col>
          </el-row>
          <el-row>
          <el-col :span="24" v-if="form.stopDate">
          <el-form-item label="终止日期:" >
            <span style="word-break:break-all">{{form.stopDate}}</span>
          </el-form-item>
          </el-col>
          </el-row>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button style="margin-right:10px" @click="dialogVisibleDesc = false">取消</el-button> -->
      </div>
    </el-dialog>
    <conPtf ref="ptfshow"></conPtf>
  </div>
  
</template>
<script>
import {contractOrderCensorship_page,contractStatus,applyType,contractOrderCensorship_censorship,contractOrderCensorship_detail,getDate} from "../../RequestPort/apply/conError.js"
import conPtf from './conPtf.vue'
export default {
  components: {conPtf},
  name: 'conntract',
  mixins: [],
  data() {
    return {
      search: { current: 1, size: 100,type:''}, //搜索
      searchDatatime:[],
      total: 10,
      tableData: [], //列表对象
      options:[],
      statusList:contractStatus(),
      applyList:applyType(),
      dialogVisible:false,
      form:{urlList:[],startDate:getDate()},
      rule:{
      },
      rulesApp:{},
      dialogVisibleDesc:false,
      isCheckBokS:true,
      parentName:'',
      showtype:false,
      teamList:[],
      repairBeforFileFormData: new FormData(),
      repairBeforFilefileList:[],
      isturn:0,
      source:1,
      dataList:[{label:'签订日期',value:'1'},{label:'截止日期',value:'2'}],
      teamDsc:{},
      disabled:false,
      dialogVisibleApply:false,
      applyName:'',
      pickerOptions:{},
      applyflag:'',
      activeName:'first'
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
      sort_change(column){
      this.search.sortColumn=column.prop;
      if(column.order=='ascending'){
        this.search.sortKey =0
      }else{
        this.search.sortKey =1
      }
      this.getList(1);
    },
      lookpft(row){
        this.$refs.ptfshow.previewPDF(row.annexUrl);
      },
     
    getList(current) {
      if(this.searchDatatime){
        this.search.startTime = this.searchDatatime[0]
        this.search.endTime = this.searchDatatime[1]
      }
      
      if (current) {
        this.search.current = 1
      }
      //查列表
      contractOrderCensorship_page(this.search).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
   
    //重置查询条件
    reset() {
      ;(this.search = {
        current: 1,
        size: 100,
       
      }),
      this.searchDatatime = [];
        this.getList(1)
    },
   
    
    handleSizeChange(val) {
      this.search.size = val
      this.getList(1)
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.getList()
    },
    clearnF() {
      this.form = {
        startDate:getDate(),
        reason:'',
        stopDate:''
      }
    },
    desc(row){
      var tabletype = row.typeCode==1?'revoke':'stop'
        contractOrderCensorship_detail({id:row.id,tableType:tabletype}).then(res=>{
          if(res.code==200){
            this.form = res.data;
            this.dialogVisibleDesc = true;
          }
        })
    },
   
    apply(row){
      this.form.id = row.id;
      this.applyflag = row.typeCode==2?'stop':'del';
      this.form.reason = row.reason;
      this.$set(this.form,'stopDate',row.stopDate)
     // this.form.stopDate = row.stopDate;
      if(this.applyflag=='del'){
        this.applyName = '作废';
      }else{
        this.applyName = '终止';
        this.pickerOptions= {
          disabledDate: time => {
            let d = new Date(row.endDate)
            let curDate = new Date();
            var preDate = new Date(curDate.getTime() - 24*60*60*1000)
            return time.getTime() < preDate || time.getTime() > d.getTime();
          }
        }
      }
      
      this.dialogVisibleApply = true;
    },
    saveApply(){
      this.$refs['Applyform'].validate(valid => {
        if (valid) {
          var data = {id:this.form.id,tableType:this.applyflag=='del'?'revoke':'stop'}
          if(this.form.stopDate){
            data.stopDate = this.form.stopDate;
          }
            contractOrderCensorship_censorship(data).then(res=>{
              if(res.code=='200'){
                this.$message.success(this.applyName+'成功');
                this.clearnF()
                this.getList(1);
                this.dialogVisibleApply=false;
              }
          })
          
        }
      })
    }
  }
}
</script>

<style  lang="less" scoped>
::v-deep .el-upload--picture-card{width:100px;height:100px;line-height:100px}
::v-deep .el-upload-list--picture-card .el-upload-list__item{width:100px;height:100px;line-height:100px}
.searchType{width:210px;box-shadow: 1px 1px 5px #888888;position:absolute;z-index:100;background-color:#fff}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 10px;
  background-color: #ffffff;
}
.SearchInput {
  width: 100%;
}
.SearchOptions {
  width: 100%;
}
.SearchBtn {
  margin-left: 3.7%;
}
.SearchBtn2 {
  margin-left: 20px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
   background-color: #ffffff;
}

.all {
  background-color:#f3f4f8;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 82%;
}
.lab {
  margin-left: 20px;
}
.dialog_form {
  margin: 30px;
  padding: 10px;
}
.red {
  color: #f74b4b;
}
.radioList{
  display:block;margin:10px 0px
}
</style>
