import axios from '@/common/js/request'

export function contractOrderCensorship_page(data){
    const contractOrderCensorship_page = axios({
        url: '/contractOrderCensorship/page',
        method: 'get',
        params:data
    })
    return contractOrderCensorship_page
}
export function contractStatus(){
    const list = [
        {value:1,label:'待处理'},
        {value:2,label:'已处理'}
    ]
    return list;
}
export function applyType(){
    const list = [
        {value:1,label:'申请作废'},
        {value:2,label:'申请终止'}
    ]
    return list;
}


export function contractOrderCensorship_censorship(data){
    const contractOrderCensorship_censorship = axios({
        url: '/contractOrderCensorship/censorship',
        method: 'post',
        data:data
    })
    return contractOrderCensorship_censorship
}
export function contractOrderCensorship_detail(data){
    const contractOrderCensorship_detail = axios({
        url: '/contractOrderCensorship/detail',
        method: 'get',
        params:data
    })
    return contractOrderCensorship_detail
}

export function getDate() {
    var myDate = new Date();
    var month = parseInt(myDate.getMonth())+1;
    month = (Array(2).join(0) + month).slice(-2)
    var date = myDate.getFullYear()+"-"+month+"-"+myDate.getDate();
    return date;
  }